@import "design-styles.scss";

html,
body {
    position: relative;
    min-height: 100vh;
    height: auto;
    min-width: 320px;
}

a {
    outline: none;
}

.mat-progress-bar {
    height: 10px !important;
    border-radius: 5px;

    .mat-progress-bar-fill {
        transition: transform 500ms ease-out !important;
    }
}

.mr-10 {
    margin-right: 10px;
}
.ml-10 {
    margin-left: 10px;
}

.content-w {
    max-width: 1360px;
    margin: 0 auto;
}

.mat-menu-panel {
    min-width: 90px !important;
    background-color: $white;
    border: 1px solid $gray-light;

    .mat-menu-content {
        color: $blue-dark;
    }
}

.mat-expansion-panel {
    background-color: $white;
    color: $blue-dark;

    .mat-expansion-panel-header {
        background-color: $white !important;
    }

    .mat-expansion-panel-header-title {
        color: $blue-dark;
    }

    .mat-expansion-panel-header-description,
    .mat-expansion-indicator::after {
        color: $gray-light;
    }
}

.mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: unset;
    .mat-stepper-vertical-line::before {
        background: #BFCDD9;
    }


    .mat-step-header {
        .mat-step-label {
            color: #6093BF
        }
        .mat-step-icon {
            background: #95ACBF;
        }
        .mat-step-icon-selected {
            background: #2F4659;
        }
    }
}


@keyframes buttonAnimate {
    0% {
        left: -50%;
    }
    25% {
        left: -50%;
    }
    40% {
        left: 120%;
    }
    100% {
        left: 120%;
    }
}

@media screen and (max-width: 480px) {
    .cdk-global-scrollblock {
        position: static;
        overflow-y: hidden !important;
    }
} // fix ios bug