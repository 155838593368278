@import "assets/styles/material-theme.scss";
@import "assets/styles/common.scss";
@import "assets/styles/design-styles.scss";
@import "assets/styles/fonts.scss";
@import "aos/dist/aos.css";

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Helvetica Neue", sans-serif;
}
