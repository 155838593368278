$white: #ffffff;
$blue-dark: #2f4659;
$blue-medium: #6093bf;
$blue-light: #89b3d9;
$gray-medium: #95acbf;
$gray-light: #bfcdd9;
$brown: #bf948a;

@function strip-unit($number) {
    @if type-of($number) == "number" and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function calcFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
    $f-min: strip-unit($f-min);
    $f-max: strip-unit($f-max);
    $w-min: strip-unit($w-min);
    $w-max: strip-unit($w-max);

    $k: ($f-max - $f-min)/ ($w-max - $w-min);
    $b: $f-min - $k * $w-min;

    $b: $b + $units;

    @return calc(#{$k} * 100vw + #{$b});
}

@mixin fontSize($f-min, $f-max, $w-min, $w-max) {
    font-size: $f-min;

    @media (min-width: $w-min) {
        font-size: calcFontSize($f-min, $f-max, $w-min, $w-max, px);
    }
    @media (min-width: $w-max) {
        font-size: $f-max;
    }
}

._text-small {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    @include fontSize(16px, 18px, 480px, 1280px);
    line-height: 1.4;
    margin: 0.3em 0;
}

._text-medium {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    @include fontSize(18px, 24px, 480px, 1280px);
    line-height: 1.3;
}

._text-large {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    @include fontSize(22px, 28px, 480px, 1280px);
    line-height: 1.3;
}

._title-large {
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    text-align: center;
    text-transform: uppercase;
    @include fontSize(40px, 60px, 480px, 1280px);
    line-height: 1.3;
}

._title {
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: bold;
    @include fontSize(32px, 44px, 480px, 1280px);
    line-height: 1.2;
    margin: 0.2em 0;
}

._title-small {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    @include fontSize(16px, 18px, 480px, 1280px);
    line-height: 1.4;
    margin: 0.3em 0;
    text-transform: uppercase;
}

._link {
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    @include fontSize(16px, 22px, 480px, 1280px);
    line-height: 1.2;
    text-transform: uppercase;
}

._page-title {
    @include fontSize(32px, 76px, 480px, 1920px);
    font-family: "Roboto";
    font-style: normal;
    font-weight: normal;
    line-height: 1.3;
    text-align: center;
    text-transform: uppercase;
    text-shadow: -1px 0 #285b84, 0 1px #285b84, 1px 0 #285b84, 0 -1px #285b84;
    margin: 0;
}

._page-subtitle {
    @include fontSize(20px, 38px, 480px, 1280px);
    font-family: "Roboto Slab";
    font-style: normal;
    font-weight: bold;
    line-height: 1.4;
    text-align: center;
    margin: 10px 0 0;
}

._sub-page-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 65vh;
    position: relative;

    .text {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 100px 10% 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        ._page-title {
            color: $white;
            font-weight: bold;
        }
    }
}