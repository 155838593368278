@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto/Roboto-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto/Roboto-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto/Roboto-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto";
    src: url("../../assets/fonts/Roboto/Roboto-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Roboto Slab";
    src: url("../../assets/fonts/RobotoSlab/RobotoSlab-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto Slab";
    src: url("../../assets/fonts/RobotoSlab/RobotoSlab-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Roboto Slab";
    src: url("../../assets/fonts/RobotoSlab/RobotoSlab-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
